/**
 * 判斷是否為外部 URL
 */
export const externalUrlRegex = /^https?:\/\//;

/**
 * 判斷電話號碼開頭是否為台灣 0 或 886, 並判斷整個字串是否出現 +886
 */
export const twPhoneNumberRegex = /^(0|886)|(\+886)/g;

/**
 * 可以將 "TW(+886)“ "AC(+247)" 等手機區碼格式改成 “+886” "+247" 等格式
 * 以在 DropdownSelectInputBig 使用時可以簡潔顯示
 * （只用在 issue-#1952 TravelReservationStepInfo 旅遊行程報名填寫這步驟
 * 利用 isTravelParticipantUse 屬性來控制 DropdownSelectInputBig 組件
 * 是否要以這樣格式顯示
 */
export const extractCountryCode = (input: string) => {
	const match = input.match(/\(\+\d+\)/);

	return match ? match[0].slice(1, -1) : '';
};

/**
 * 匹配 URL 中目前地區（locale）的 route 字串
 *
 * 例如： /TW/ 或 /JP/
 */
export const localeUrlRegex = /^\/([A-Za-z]{2})\//;

/**
 * 刪除所有空白字元
 */
export const stringWithoutSpaces = /\s/g;
